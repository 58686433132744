@font-face {
  font-family: 'iot';
  src:  url('./assets/fonts/iot-icons/iot.eot?ti8ltp');
  src:  url('./assets/fonts/iot-icons/iot.eot?ti8ltp#iefix') format('embedded-opentype'),
    url('./assets/fonts/iot-icons/iot.ttf?ti8ltp') format('truetype'),
    url('./assets/fonts/iot-icons/iot.woff?ti8ltp') format('woff'),
    url('./assets/fonts/iot-icons/iot.svg?ti8ltp#iot') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iot-"], [class*=" iot-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iot' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iot-pause-in-circle:before {
  content: "\e967";
  color: #909098;
}
.iot-stop-in-circle:before {
  content: "\e966";
  color: #909098;
}
.iot-pause:before {
  content: "\e964";
  color: #909098;
}
.iot-disconnected-filled:before {
  content: "\e960";
  color: #909098;
}
.iot-warning-filled:before {
  content: "\e961";
  color: #909098;
}
.iot-broken-heart-filled:before {
  content: "\e962";
  color: #909098;
}
.iot-heart-filled:before {
  content: "\e963";
  color: #909098;
}
.iot-sync-filled:before {
  content: "\e965";
  color: #909098;
}
.iot-winter:before {
  content: "\e95f";
  color: #909098;
}
.iot-rise:before {
  content: "\e95d";
  color: #909098;
}
.iot-fall:before {
  content: "\e95e";
  color: #909098;
}
.iot-sunrise:before {
  content: "\e95b";
  color: #909098;
}
.iot-sunset-2:before {
  content: "\e95c";
  color: #909098;
}
.iot-clear:before {
  content: "\e944";
  color: #909098;
}
.iot-cloudy:before {
  content: "\e945";
  color: #909098;
}
.iot-drizzle:before {
  content: "\e946";
  color: #909098;
}
.iot-flurries:before {
  content: "\e947";
  color: #909098;
}
.iot-fog-light:before {
  content: "\e948";
  color: #909098;
}
.iot-fog:before {
  content: "\e949";
  color: #909098;
}
.iot-freezing-drizzle:before {
  content: "\e94a";
  color: #909098;
}
.iot-freezing-rain-heavy:before {
  content: "\e94b";
  color: #909098;
}
.iot-freezing-rain-light:before {
  content: "\e94c";
  color: #909098;
}
.iot-freezing-rain:before {
  content: "\e94d";
  color: #909098;
}
.iot-ice-pellets-heavy:before {
  content: "\e94e";
  color: #909098;
}
.iot-ice-pellets-light:before {
  content: "\e94f";
  color: #909098;
}
.iot-ice-pellets:before {
  content: "\e950";
  color: #909098;
}
.iot-mostly-clear:before {
  content: "\e951";
  color: #909098;
}
.iot-mostly-cloudy:before {
  content: "\e952";
  color: #909098;
}
.iot-partly-cloudy:before {
  content: "\e953";
  color: #909098;
}
.iot-rain-heavy:before {
  content: "\e954";
  color: #909098;
}
.iot-rain-light:before {
  content: "\e955";
  color: #909098;
}
.iot-rain:before {
  content: "\e956";
  color: #909098;
}
.iot-snow-heavy:before {
  content: "\e957";
  color: #909098;
}
.iot-snow-light:before {
  content: "\e958";
  color: #909098;
}
.iot-snow:before {
  content: "\e959";
  color: #909098;
}
.iot-t-storm:before {
  content: "\e95a";
  color: #909098;
}
.iot-thumbnails:before {
  content: "\e943";
  color: #909098;
}
.iot-pressure:before {
  content: "\e941";
  color: #909098;
}
.iot-wind:before {
  content: "\e942";
  color: #909098;
}
.iot-monitor:before {
  content: "\e922";
  color: #909098;
}
.iot-tv:before {
  content: "\e923";
  color: #909098;
}
.iot-speaker:before {
  content: "\e924";
  color: #909098;
}
.iot-washing-machine:before {
  content: "\e925";
  color: #909098;
}
.iot-table-lamp:before {
  content: "\e926";
  color: #909098;
}
.iot-bedside-lamp:before {
  content: "\e927";
  color: #909098;
}
.iot-exit:before {
  content: "\e928";
  color: #909098;
}
.iot-sunset:before {
  content: "\e929";
  color: #909098;
}
.iot-chandelier:before {
  content: "\e92a";
  color: #909098;
}
.iot-dryer:before {
  content: "\e92b";
  color: #909098;
}
.iot-coffee-maker:before {
  content: "\e92c";
  color: #909098;
}
.iot-sleep:before {
  content: "\e92d";
  color: #909098;
}
.iot-eco:before {
  content: "\e92e";
  color: #909098;
}
.iot-plane:before {
  content: "\e92f";
  color: #909098;
}
.iot-cooker:before {
  content: "\e930";
  color: #909098;
}
.iot-dishwasher:before {
  content: "\e931";
  color: #909098;
}
.iot-kettle:before {
  content: "\e932";
  color: #909098;
}
.iot-cooker-hood:before {
  content: "\e933";
  color: #909098;
}
.iot-lamp:before {
  content: "\e934";
  color: #909098;
}
.iot-floor-lamp:before {
  content: "\e935";
  color: #909098;
}
.iot-fridge:before {
  content: "\e938";
  color: #909098;
}
.iot-gamepad:before {
  content: "\e939";
  color: #909098;
}
.iot-options:before {
  content: "\e93a";
  color: #909098;
}
.iot-light-2:before {
  content: "\e93b";
  color: #909098;
}
.iot-hairdryer:before {
  content: "\e93c";
  color: #909098;
}
.iot-microwave:before {
  content: "\e93d";
  color: #909098;
}
.iot-blender:before {
  content: "\e93e";
  color: #909098;
}
.iot-projector:before {
  content: "\e93f";
  color: #909098;
}
.iot-small-lamp:before {
  content: "\e940";
  color: #909098;
}
.iot-electricity:before {
  content: "\e91a";
  color: #909098;
}
.iot-flame:before {
  content: "\e91b";
  color: #909098;
}
.iot-help:before {
  content: "\e91f";
  color: #909098;
}
.iot-notification:before {
  content: "\e920";
  color: #909098;
}
.iot-water:before {
  content: "\e921";
  color: #909098;
}
.iot-gas-meter:before {
  content: "\e918";
  color: #909098;
}
.iot-smoke-detector:before {
  content: "\e919";
  color: #909098;
}
.iot-lock-open:before {
  content: "\e917";
  color: #909098;
}
.iot-lock:before {
  content: "\e916";
  color: #909098;
}
.iot-alarm:before {
  content: "\e91c";
  color: #909098;
}
.iot-warning:before {
  content: "\e91d";
  color: #909098;
}
.iot-electricity-meter:before {
  content: "\e91e";
  color: #909098;
}
.iot-thermometer:before {
  content: "\e915";
  color: #909098;
}
.iot-drop:before {
  content: "\e910";
  color: #909098;
}
.iot-socket:before {
  content: "\e911";
  color: #909098;
}
.iot-thermostat:before {
  content: "\e912";
  color: #909098;
}
.iot-water-meter:before {
  content: "\e913";
  color: #909098;
}
.iot-motion-detector:before {
  content: "\e914";
  color: #909098;
}
.iot-play:before {
  content: "\e973";
  color: #909098;
}
.iot-co:before {
  content: "\e936";
  color: #909098;
}
.iot-co2:before {
  content: "\e937";
  color: #909098;
}
.iot-star:before {
  content: "\e901";
  color: #909098;
}
.iot-chart:before {
  content: "\e907";
  color: #909098;
}
.iot-drops:before {
  content: "\e908";
  color: #909098;
}
.iot-weather:before {
  content: "\e909";
  color: #909098;
}
.iot-shutter:before {
  content: "\e90a";
  color: #909098;
}
.iot-magnet:before {
  content: "\e90b";
  color: #909098;
}
.iot-fan:before {
  content: "\e90c";
  color: #909098;
}
.iot-panel:before {
  content: "\e90d";
  color: #909098;
}
.iot-recuperation:before {
  content: "\e90e";
  color: #909098;
}
.iot-light:before {
  content: "\e90f";
  color: #909098;
}
.iot-energy:before {
  content: "\e902";
  color: #909098;
}
.iot-overview:before {
  content: "\e903";
  color: #909098;
}
.iot-scene:before {
  content: "\e904";
  color: #909098;
}
.iot-settings:before {
  content: "\e905";
  color: #909098;
}
.iot-security:before {
  content: "\e906";
  color: #909098;
}
.iot-device:before {
  content: "\e900";
  color: #909098;
}
